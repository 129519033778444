'use client';

import React from 'react';

import { Button } from '@mui/material';

import Image from '@/app/_components/image';
import { Designer } from '@/types/designers';

import classes from './designer-item.module.scss';

const maxLengthDescription = 300;

interface Props {
  designer: Designer;
  priority: boolean;
}

const DesignerItem: React.FC<Props> = (props) => {
  const { designer, priority } = props;

  const description = (() => {
    if (!designer.description) return '';

    if (designer.description.length < maxLengthDescription) {
      return designer.description;
    }

    return `${designer.description.slice(0, maxLengthDescription)}...`;
  })();

  return (
    <div className={classes.wrap}>
      <div className={classes.imgWrapper}>
        <Image
          src={designer.options.collage?.largeMediaUrl || ''}
          alt={designer.name || 'Designer'}
          fill
          priority={priority}
        />
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.logoImg}>
          <Image src={designer.imageUrl || ''} alt="" fill />
        </div>
        {description ? (
          <div className={classes.description}>{description}</div>
        ) : null}
        {designer.site ? (
          <div className={classes.btnWrapper}>
            <Button href={designer.site} prefetch={false} target="_blank">
              go to website
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DesignerItem;
